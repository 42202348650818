export default function useImages() {
  const getImagePrefix = (image: string) => {

    const runtimeConfig = useRuntimeConfig();

    if (runtimeConfig.public.environmentName === 'development' && image.indexOf('http') === -1) {
      if (image.indexOf('siteassets') !== -1 || (image.indexOf('globalassets') !== -1)) {
        return `http://localhost:8092${image}`;
      }
    }
    
    return image;
  };

  return {
    getImagePrefix,
  };
}
